<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-10">
        <TemperaturForm />
      </div>
      <div class="d-none col-md-2">
        <div class="imageContainerLogin my-3" >
          <img src="/img/logo.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemperaturForm from '@/components/TemperaturForm'
export default {
  name: 'AppDashboard',
  components: {
    TemperaturForm
  }
  // setup() {},
}
</script>
